.nhsuk-search {
  width: 100%;
  max-width: initial;
  font-size: 1.5rem;
  padding: 2rem 1rem;
}

.nhsuk-search:not(:focus) {
  border: 2px solid #212b32;
  box-shadow: 0 0 0 4px transparent;
  outline: 4px solid transparent;
  outline-offset: 4px;
}
