$ocean-blue: #2587d8;

.time-slots-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 10px;

  .time-slot {
    position: relative;
    margin: 5px;

    .time {
      border-color: $ocean-blue;
      border: 1px solid;
      padding: 15px;
      border-radius: 5px;
      text-align: center;
    }

    .progress {
      height: 2em;
      width: 100%;
      background-color: $ocean-blue;
      border: 1px solid;
      border-color: black;
      position: relative;
      margin-top: 10px;
      border-radius: 5px;
      color: white;
      font-size: 0.6em;
      text-align: center;
    }
  }
}
