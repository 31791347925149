.participant-details {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #ffffff;
  white-space: pre-line;
}

.save-row {
  flex-direction: row;
  display: flex;
  align-items: center;

  .nhsuk-button {
    margin-left: 34px;
    margin-bottom: 0;
  }

  .nhsuk-form-group {
    margin-bottom: 0;
  }
}
