.back-to-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -1.5rem;
  margin-bottom: 3rem;
  .back-to-top__icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}
