@import "../../../node_modules/nhsuk-frontend/packages/nhsuk";

.pagination-number-div {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
}

.pagination-numbers {
  display: inline-block;
  width: max-content;
}

.pagination-number {
  padding: 20px;
}

.pagination-number:hover {
  background-color: $color_nhsuk-grey-4;
}

.pagination-ends {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 25%;
}

.active-number {
  background-color: $nhsuk-link-color;
  color: $color_nhsuk-white;
}

.active-number:hover {
  background-color: $nhsuk-link-color;
  color: $color_nhsuk-white;
}

.ellipses {
  color: $nhsuk-link-color;
}

.custom-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
