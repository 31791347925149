@import "../../index";

.ecrf {
  .summary {
    h3 {
      font-size: large;
    }
  }
  .d-inline-block {
    display: inline-block;
  }
  .mb-4 {
    margin-bottom: 2rem;
  }
  .readonly-answer {
    font-size: large;
  }
  .form-section-display-order {
    height: 4rem;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }
  .form-section-depth {
    padding-left: 24px;
    border-left: 4px solid #d8dde0;
  }

  .btn-secondary:hover {
    background-color: $nhsuk-secondary-button-hover-color;
  }

  .btn-secondary:active {
    background: $nhsuk-secondary-button-active-color;
    box-shadow: none;
    color: #fff;
    top: 4px;
  }

  .btn-secondary:focus {
    background: $nhsuk-focus-color;
    box-shadow: 0 4px 0 $nhsuk-box-shadow-color;
    color: $nhsuk-focus-text-color;
    outline: 4px solid transparent;
  }

  .btn-secondary[disabled="disabled"],
  .btn[disabled],
  .btn:disabled {
    background-color: $nhsuk-secondary-button-color;
    opacity: 0.5;
    pointer-events: none;
  }

  .btn-secondary {
    background-color: $nhsuk-secondary-button-color !important;
    box-shadow: 0 4px 0 $nhsuk-secondary-button-shadow-color !important;
  }

  .btn:active {
    background: $nhsuk-button-active-color;
    box-shadow: none;
    color: #fff;
    top: 4px;
  }

  .btn:focus {
    background: $nhsuk-focus-color;
    box-shadow: 0 4px 0 $nhsuk-focus-text-color;
    color: $nhsuk-focus-text-color;
    outline: 4px solid transparent;
  }

  .btn:hover {
    background-color: $nhsuk-button-hover-color;
  }

  .btn[disabled="disabled"],
  .btn[disabled],
  .btn:disabled {
    background-color: $nhsuk-button-color;
    opacity: 0.5;
    pointer-events: none;
  }

  .btn {
    -webkit-appearance: none;
    background-color: $nhsuk-button-color;
    border: 2px solid transparent;
    border-radius: 4px;
    box-shadow: 0 4px 0 $nhsuk-box-shadow-color;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    margin-top: 0;
    padding: 12px 16px;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: auto;
  }

  .help-toggle {
    padding: 0 5px 0 5px;
  }

  .field-group-help-text {
    padding-left: 24px;
    margin-bottom: 1rem;
    border-left: 4px solid #d8dde0;
  }

  .form-section-display-order {
    font-size: larger;
    height: 4rem;
    padding-top: 1rem;
  }

  .form-section-depth {
    margin-top: 1rem;
  }
}
