.date-pickers {
  display: flex;
  padding-bottom: 40px;
  max-width: 500px;
  flex-wrap: wrap;
  column-gap: 40px;
}

.date-pickers label {
  width: 216px;
}

.duet-date {
  thead th,
  td {
    border-bottom: none;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  // more closely match the NHS input styling
  .duet-date__input {
    border: 2px solid #4c6272;
    padding: 7px 0 6px 7px;
    font-size: 1.1875rem;
  }
  .duet-date__toggle {
    // push away from the slightly bigger border above
    right: 2px;
    top: 2px;
    height: calc(100% - 4px);
    // avoid appearing over the top of select dropdown
    z-index: 0;
  }
}

.nhsuk-form-group.detail-form-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-bottom: 40px;

  .user-email,
  .participant-id {
    width: 15rem;
  }

  .event-type-select {
    width: 20rem;
    .event-type-select__control {
      border: 2px solid #4c6272;
      border-radius: 0;
    }
  }
}
