.checkboxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 24px;

  .nhsuk-checkboxes__item:last-of-type {
    margin-bottom: 8px;
  }
}
