@import "../../../node_modules/nhsuk-frontend/packages/core/settings/_colours.scss";

.loading {
  color: $color_nhsuk-grey-4;
  background-color: $color_nhsuk-grey-4;
  pointer-events: none;
}

.audit-v2-table {
  table-layout: fixed;
  td {
    overflow-wrap: break-word;
  }

  .event-data-col {
    width: 50%;
  }
}

.audit-v2-details {
  margin-bottom: 0;
}
