.nhsuk-search-select {
  font-size: 1.5rem;
  padding: 1rem;
  height: 68px;
}

.nhsuk-search-select:not(:focus) {
  border: 2px solid #212b32;
  box-shadow: 0 0 0 4px transparent;
  outline: 4px solid transparent;
  outline-offset: 4px;
}
