.button-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;

  button {
    margin: 10px;
  }
}
