@import "../node_modules/nhsuk-frontend/packages/nhsuk";
@import "~nhsuk-react-components-extensions/css/components.css";

$grail-purple: #54174b;

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // duet date picker styling
  --duet-color-primary: #005fcc;
  --duet-color-text: #333;
  --duet-color-text-active: #fff;
  --duet-color-placeholder: #666;
  --duet-color-button: #f5f5f5;
  --duet-color-surface: #fff;
  --duet-color-overlay: rgba(0, 0, 0, 0.8);
  --duet-color-border: #333;

  --duet-font-normal: 400;
  --duet-font-bold: 600;

  --duet-radius: 0;
  --duet-z-index: 600;
}

.content {
  flex: 1 0 auto;
}

.link-button-body {
  @extend .link-button;
  font-size: 19px;
}

.link-button {
  @include nhsuk-link-style-default;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.nhsuk-back-link__link:hover {
  cursor: pointer;
}

.nhsuk-header__navigation.logged-in {
  background-color: $grail-purple;
}

.nhsuk-footer.logged-in {
  border-top-color: $grail-purple;
}

.nhsuk-header__navigation {
  margin: 0;
  width: 100%;
  max-width: 100%;
  background-color: $color_nhsuk-blue;

  & > .nhsuk-menu-container {
    margin: 0 auto;
  }

  .nhsuk-header__navigation-title {
    color: $color_nhsuk-white;
    font-weight: bold;
  }

  .nhsuk-header__navigation-list {
    .nhsuk-header__navigation-link,
    .nhsuk-header__navigation-item {
      color: $color_nhsuk-white;

      &:active {
        color: $color_nhsuk-black;
      }
    }
  }
}

.nhsuk-header__logos {
  display: flex;

  @media (max-width: 50rem) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    :not(:first-child) {
      margin-top: 1.25rem;
    }
  }

  @media (min-width: 50rem) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    :first-child {
      margin-right: auto;
    }

    :not(:first-child) {
      padding-left: 1.5rem;
    }
  }
}

.nhsuk-header__navigation-list {
  justify-content: left;
  min-height: 3.25rem;
}

.nhsuk-header__menu-account {
  margin: 0;
  padding: 0.25rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .nhsuk-header__menu-link,
  .nhsuk-header__menu-link:visited {
    color: $color_nhsuk-white;

    &:hover,
    &:focus {
      color: $color_nhsuk-black;
    }

    &:hover:not(:active) {
      background-color: $color_nhsuk-white;
      box-shadow: $color_nhsuk-white 0px -2px 0px 0px,
        $nhsuk-box-shadow 0px 4px 0px 0px;
      box-sizing: border-box;
    }
  }
}

.nhsuk-account-user__username {
  font-family: "Frutiger W01", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  padding-right: 1rem;
}

.nhsuk-color-green {
  color: $color_nhsuk-green;
}

.nhsuk-main-wrapper {
  padding-bottom: 3rem;
}

.nhsuk-button-margin-right {
  margin-right: 16px;
}

.nhsuk-error-message {
  white-space: pre;
}

.nhsuk-checkboxes__input:indeterminate + .nhsuk-checkboxes__label::after {
  opacity: 1;
  border: none;
  height: 5px;
  width: 20px;
  top: 17px;
  background-color: $color_nhsuk-black;
  transform: none;
}

.sort-arrows {
  position: absolute;
  display: inline-block;
  align-items: center;
  margin-left: 0.5rem;
  padding-right: 2rem;
  cursor: pointer;

  .sort-arrow-desc {
    top: 0.25rem;
  }
}

.sort-arrow-asc {
  height: 0;
  width: 0;
  border-top: 0.5rem solid black;
  border-left: 0.5rem solid transparent;
  transform: rotate(-45deg);
}

.sort-arrow-desc {
  height: 0;
  width: 0;
  border-bottom: 0.5rem solid black;
  border-right: 0.5rem solid transparent;
  transform: rotate(-45deg);
}

.expand-arrow-up {
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 12px solid $nhsuk-secondary-text-color;
  cursor: pointer;
}

.expand-arrow-down {
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid $nhsuk-secondary-text-color;
  cursor: pointer;
}
